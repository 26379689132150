<template>
  <v-container fluid class="container">
    <v-row justify="center" class="mt-10">
      <v-col xs="12" sm="8" md="6" xl="4">
        <v-card>
          <v-img
            height="300"
            src="/img/splash-min.jpg"
            position="bottom center "
          ></v-img>
          <div class="pa-10">
            <h2>
              About Brrds.io
            </h2>

            <p class="body-2">
              This app was built in 2020 to store researcher Steve's research
              data safely in the cloud. Working on remote islands without
              Internet connection, the heroic bird observers used to be confined
              to pen and paper notes, prone to errors, and tedious to enter into
              the database once back at home. Brrds.io, promising a Better
              Research Registration Database System brings a new level of
              comfort to remote island research.
            </p>

            <h2 class="mt-10">
              Technical overview
            </h2>
            <p class="body-2">
              Brrds.io is a Progressive Web App running on Heroku with CouchDb
              database. Progressive Web App means it installs on any device as
              an app, just click "save to home screen" on your phone or tablet.
              It works equally well offline, without Internet access, and can
              sync data to the cloud once back in reach of civilization again.
              It has tons of smart features, like error warnings when entering
              incorrect IDs, or incorrect observations of live birds, that have
              been found dead previously.
            </p>
            <h2 class="mt-10">
              Get your own app and improve your field work
            </h2>
            <p class="body-2">
              Brrds.io has kindly been released as open source by Steve to help
              other researchers across the world in their everyday field work
              struggles, and can be downloaded from github. Just get in touch
              and we can send you a link. For modifications or help setting up
              your own installation, reach out to
              <a href="https://pactmedia.org/" target="_blank">Pact</a> and
              <a href="https://www.azote.se/" target="_blank">Azote</a>, the two
              brilliant science communication agencies that made Steve's dreams
              come true.
            </p>
            <p class="font-weight-medium mt-10">
              <a class="mb-5" href="https://www.azote.se/" target="_blank"
                ><v-icon color="primary">mdi-chevron-right</v-icon>Azote
              </a>
              <a href="https://pactmedia.org/" target="_blank"
                ><v-icon color="primary">mdi-chevron-right</v-icon>Pact
              </a>
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
p {
  // color: #fffafa;
}
.font-weight-medium a {
  display: block;
  text-decoration: none;
}
.container {
  // background-image: url('/img/splash-min.jpg');
  // background-position: top;
  // background-repeat: no-repeat;
  // position: absolute;
  // height: 100vh;
  // width: 100%;
  // background-size: cover;
}
</style>
